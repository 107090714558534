export const myTheme = {
	global: {
	  colors: {
		brand: '#FF956E',
		background: '#383838',
		'background-contrast': '#e4dddd',
		text: '#000000', // Using black for font color
		'accent-1': '#FF956E',

		'neutral-1': '#e4dddd',
	  },
	  font: {
		family: 'Oxygen, sans-serif', // Default body font
		color: 'text',
		size: '18px',
	  },
	},
	heading: {
	  font: {
		family: "'Inter-Bold', Arial, sans-serif", // Using Inter-Bold for headings
		color: 'neutral-1'
	  },
	},
	button: {
	  border: {
		radius: '5px',
	  },
	  font: {
		family: "'Hack-Bold'", // Optionally, use Hack-Bold for buttons
	  },
	},
	// Example of using GeistMono-Bold for a custom component or specific usage
	// This requires you to define or extend a component that utilizes this style
	customCode: {
	  font: {
		family: "'GeistMono-Bold', monospace",
	  },
	},
	card: {
	  container: {
		background: 'background-contrast',
		elevation: 'none',
	  },
	},
  };
