import React from 'react'
import { Header as GrommetHeader, Box, Image } from 'grommet'
import Navbar from './Navbar' // Ensure this path is correct based on your project structure
import Logo from '../../assets/Layer 2.png'

const Header = () => {
  return (
    <GrommetHeader background='brand' pad='small' height='small' align='center'>
      <Box height='medium' width='small'>
        <Image src={Logo} alt='Logo' fit='contain' />
      </Box>
      <Box height='xxsmall' width='xxsmall' />
      <Navbar />
    </GrommetHeader>
  )
}

export default Header
